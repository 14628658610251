@import 'colors';

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins100';
  src: url('../fonts/Poppins/Poppins-Thin.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins200';
  src: url('../fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins300';
  src: url('../fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins500';
  src: url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins600';
  src: url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins700';
  src: url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins800';
  src: url('../fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins900';
  src: url('../fonts/Poppins/Poppins-Black.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PoppinsItalic';
  src: url('../fonts/Poppins/Poppins-Italic.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PoppinsItalic500';
  src: url('../fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}
